.code {
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
    margin: 0;
}

.codeline {
    font-size: 16px;
}


.codeline.highlight {
    font-size: 16px;
    background-color: rgba(255,255,0, 0.5);
}


.codeline.linenumber span {
    font-weight: bold;
    display: inline-block;
    width: 20px;
}

.codeline:hover {
    background-color: rgba(128,128,128,0.2);
}


.codeline.highlight:hover {
    background-color: rgba(128,128,0,0.3);
}

.render {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 10px;
    height: 300px;
    font-size: 24px;
}

.render div {
    margin: 5px;
    padding: 5px;
    border: 1px solid gray;
    border-radius: 5px;
}

.render div:hover {
    background-color: rgba(220, 220, 220, 0.3);
}

.section-text img {
    padding: 40px;
    width: 100%;
}